<template>
  <div class="wrapper">
    <div class="container flex-col terms my-8 mobile:w-full mobile:px-2 px-1">
      <h1 class="self-center mobile:text-3xl max-w-full mobile:text-center">
        Kasutus&shy;tingimused
      </h1>
      <p>
        Käesolevad Kasutustingimused sisaldavad kõiki tingimusi, mis
        reguleerivad teie (Kliendi) juurdepääsu Platvormile ja Teenustele ning
        nende kasutamist. Platvormi ja Teenuseid pakutakse teile tingimusel, et
        tenõustute Kasutustingimustega muutmata kujul (v.a Eritingimused).
        <span class="font-medium">
          Nõustudes Kasutustingimustega, või muul punktis 3.1. sätestatud juhul,
          moodustavad Kasutustingimused õiguslikult siduva lepingu teie ja
          Haldaja vahel.
        </span>
        Palun lugege Kasutustingimusi hoolikalt. Kasutustingimustega nõustudes
        kinnitate, et olete neid lugenud, saanud neist aru ja nõustute, et need
        on teie suhtes siduvad.
      </p>
      <div class="prg-container">
        <h3>1. Mõisted</h3>
        <span
          >Kui kontekstist ei tulene teisiti, on järgmistel suure tähega
          algavatel mõistetel järgnev tähendus:</span
        >
        <div class="flex flex-row mt-4">
          <span class="point-number">1.1</span>

          <p>
            <span class="font-medium">Eritingimused</span> – tingimused, millega
            täpsustatakse, muudetakse või täiendatakse KasutustingimusiPoolte
            kokkuleppel.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.2</span>
          <p>
            <span class="font-medium">Haldaja</span> – Gewodo OÜ (registrikood
            16008050, aadress Jõe tn 3, 10151, Tallinn).
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.3</span>
          <p>
            <span class="font-medium">Infosüsteem</span> – Integreeritud
            pilvelahendus Teenuste osutamiseks, sealhulgas rakendused, tarkvara,
            riistvara, andmebaasid, liidesed, seotud meedia, dokumentatsioon,
            värskendused, versiooniuuendused ja muud nendega seotud komponendid
            või materjalid.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.4</span>
          <p>
            <span class="font-medium">Kasutustingimused</span> – käesolevad
            Platvormi ja Teenuste kasutamise tingimused.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.5</span>
          <p>
            <span class="font-medium">Kasutaja</span> – füüsiline isik, kes
            kasutab Platvormi ja Teenuseid Kliendi nimel ja volitusel.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.6</span>
          <p>
            <span class="font-medium">Kasutajakonto</span> – Kliendikontoga
            seotud Kasutaja profiil Platvormi kasutamiseks, mida kasutatakse
            Kasutaja tuvastamiseks, personaalseks ligipääsuks Teenustele ning
            seadistuste muutmiseks ja salvestamiseks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.7</span>
          <p>
            <span class="font-medium">Kliendikonto</span> – Kliendiga seotud
            profiil Platvormi kasutamiseks, mida kasutatakse Kliendi
            tuvastamiseks, Kliendiga seotud Kasutajate ligipääsuks Teenustele
            ning seadistuste muutmiseks ja salvestamiseks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.8</span>
          <p>
            <span class="font-medium">Klient</span> – majandus- või
            kutsetegevuses tegutsev isik, kes on sõlminud Haldajaga Lepingu.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.9</span>
          <p>
            <span
              ><span class="font-medium">Firmakonto</span> – profiil Kliendi
              erinevate ettevõtete või osakondadega seotud info eristamiseks
              ja/või filtreerimiseks loodud Kliendikonto raames (üks või
              mitu).</span
            >
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.10</span>
          <p>
            <span class="font-medium">Leping</span> – Haldaja ja Kliendi vahel
            vastavalt Kasutustingimuste punktile 3. sõlmitud kokkulepe Platvormi
            ja Teenuste kasutamiseks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.11</span>
          <p>
            <span class="font-medium">Pool(ed)</span> – ainsuses sõltuvalt
            kontekstist kas Haldaja või Klient, mitmuses mõlemad.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.12</span>
          <p>
            <span class="font-medium">Sisu</span> – Kasutajate poolt lisatud
            andmed, teosed ja muud materjalid (video, foto, pilt, skeem, tekst
            jms). Sisu sisaldab ka isikuandmeid, mida Klient ja/või Kasutajad
            Platvormil töötlevad. Seega sisaldavad käesolevad Kasutustingimused
            ka GDPR-i artikli 28 kohaselt nõutud andmetöötluslepingut Kliendi
            kui vastutava töötleja ja Haldaja kui volitatud töötleja vahel (vt
            Kasutustingimuste punkt 7.).
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.13</span>
          <p>
            <span class="font-medium">Platvorm</span> – Lisarakendus ja
            Infosüsteem kogumis nii veebis kui ka mobiilirakenduses.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.14</span>
          <p>
            <span class="font-medium">Teenus</span> – Lepingu alusel Kliendile
            pakutavad mis tahes teenused.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.15</span>
          <p>
            <span class="font-medium">Lisarakendus</span> – Kliendi serverisse
            või Kasutaja seadmesse vajadusel paigaldatav tarkvara info
            sünkroniseerimiseks Kliendi teiste lahenduste ja süsteemidega, mis
            toimib koos vastavate Teenustega.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.16</span>
          <p>
            <span class="font-medium">Veebileht</span> – kõikide Haldajale
            kuuluvate domeenide (näiteks gewodo.com, gewodo.ee ja teiste
            veebilehtede, mis on registreeritud domeeninime “gewodo” all
            erinevates tippdomeenides) ning nende alamdomeenide kaudu
            kättesaadavate veebidokumentide (sealhulgas piltide, videote, php-
            ja html-failide) kogum.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">1.17</span>
          <p>
            <span class="font-medium">Privaatsuspoliitika</span> – eeskiri, mis
            selgitab, kuidas Haldaja töötleb Klientide, nende esindajate,
            Kasutajate ja potentsiaalsete klientide isikuandmeid.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>2. ÜLDPÕHIMÕTTED</h3>
        <div class="flex flex-row">
          <span class="point-number">2.1</span>
          <p>
            Platvorm on majandus- ja kutsetegevuses tegutsevatele isikutele
            suunatud integreeritud tarkvaralahendus töökorralduse, müügi ja
            finantsi haldamiseks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.2</span>
          <p>
            Klient ja Kasutajad peavad ise veenduma, et Platvorm sobiks nende
            vajadustega ja neile kehtivatest seadustest tulenevate nõuetega.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.3</span>
          <p>
            Platvormi täisfunktsionaalsuse kasutamiseks peab Klient looma
            Kliendikonto.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.4</span>
          <p>
            Platvormi võib kasutada üksnes selles ulatuses, otstarbel ja
            eesmärkidel, mille jaoks Platvormi funktsionaalsus on loodud ning
            Klientidele ja Kasutajatele kättesaadavaks tehtud või milleks sama
            tüüpi tehnoloogialahendusi tavapäraselt kasutatakse. Kasutamine peab
            toimuma kooskõlas Kasutustingimuste, Platvormi tugikeskkonnas
            kättesaadavate nõuannete ja juhiste ning hea tava ja õigusaktidega.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.5</span>
          <p>
            Klient ega Kasutaja ei või:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">2.5.1</span>
            <p>
              kasutada Platvormi õigusrikkumiste toimepanemiseks ega
              õigusrikkumisele üleskutsumiseks;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.5.2</span>
            <p>
              kasutada Platvormi ulatuses, mille suhtes kehtestatud
              Kasutustingimustega ta nõus ei ole;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.5.3</span>
            <p>
              saata Platvormi kaudu teistele Kasutajatele reklaamkirju,
              masspostitusi ja muud Sisu, mis on vastuolus Kasutustingimustes
              kehtestatud nõuetega;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.5.4</span>
            <p>
              kasutada Platvormi mis tahes muul ebaseaduslikul viisil;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.5.5</span>
            <p>
              kasutada Teenuseid nende kättesaadavuse või funktsionaalsuse
              seireks või muudel konkureerimise eesmärkidel.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.6</span>
          <p>
            Haldaja teeb kõik, mida võiks temalt mõistlikult oodata, et Platvorm
            ja Teenused oleksid Kliendile kättesaadavad, toimiksid turvaliselt,
            kajastaksid uuemaid tehnoloogilisi lahendusi ja et neid oleks
            Kliendil mugav kasutada. Klient teadvustab ja nõustub, et Haldajal
            on õigus igal ajal parandada ja tõhustada Platvormi tehnilist
            ülesehitust, turvalisust, kättesaadavust ja funktsionaalsust.
            Haldaja ja Klient võivad Eritingimustes kokku leppida Platvormi
            töökindlust, kättesaadavust ja turvalisust puudutava teenustaseme
            kriteeriumid.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">2.7</span>
          <p>
            Klient arvestab ja nõustub, et Haldaja võib:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">2.7.1</span>
            <p>
              kehtestada Platvormi teatud osade või funktsionaalsuse
              kasutamiseks piiranguid (näiteks Teenuste kasutamiseks vajalik
              andmemaht, Sisu üleslaadimise kiirus, talletatava Sisu maht jne);
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.7.2</span>
            <p>
              peatada või lõpetada Platvormi pakkumise ja sulgeda selle mis
              tahes osa. Viimasel juhul esitab Haldaja Kliendile avalduse
              Lepingu lõpetamiseks Kasutustingimuste punktis 10.2. toodud
              tingimustel;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">2.7.3</span>
            <p>keelduda Platvormile juurdepääsu andmast mistahes Kasutajale.</p>
          </div>
        </div>
      </div>
      <div class="prg-container">
        <h3>3. LEPINGU SÕLMIMINE</h3>
        <div class="flex flex-row">
          <span class="point-number">3.1</span>
          <p>
            Leping loetakse sõlmituks niipea, kui:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">3.1.1</span>
            <p>
              Klient avab Haldaja poolt e-postiga saadetud kinnituslingi
              nõustudes seda tehes Kasutustingimustega ning kinnitades, et ta on
              tutvunud Privaatsuspoliitikaga; või
            </p>
          </div>
          <div class="flex">
            <span class="point-number">3.1.2</span>
            <p>
              Klient ja Haldaja on allkirjastanud Lepingu dokumendi, mis
              sisaldab viidet Kasutustingimustele ja Privaatsuspoliitikale.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">3.2</span>
          <p>
            Klient peab enne Lepingu sõlmimist Kasutustingimuste ja
            Privaatsuspoliitikaga põhjalikult tutvuma ja andma Haldaja nõudmisel
            kinnituse, et ta on seda teinud. Klient peab tagama, et ka tema
            Kasutajad on Kasutustingimuste ja Privaatsuspoliitikaga põhjalikult
            tutvunud.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">3.3</span>
          <p>
            Lepingu sõlmimisel Klient või tema esindaja kinnitab, et:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">3.3.1</span>
            <p>
              kõik tema esitatud andmed ja antud kinnitused on täpsed, õiged,
              täielikud ja asjakohased;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">3.3.2</span>
            <p>
              ta on täielikult teovõimeline füüsiline isik (vähemalt
              18-aastane);
            </p>
          </div>
          <div class="flex">
            <span class="point-number">3.3.3</span>
            <p>
              tal on kõik õigused ja volitused Kliendi nimel Lepingu sõlmimiseks
              ja Platvormi ning Teenuste kasutamiseks.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">3.4</span>
          <p>
            Eelnimetatud kinnituste õigsust eeldatakse ja Haldaja ei ole
            kohustatud, aga võib neid kontrollida.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">3.5</span>
          <p>
            Haldajal on õigus keelduda Lepingu sõlmimisest ükskõik millise
            isikuga, isegi kui see isik on nõustunud Kasutustingimustega.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">3.6</span>
          <p>
            Kasutustingimused ja Privaatsuspoliitika jäävad Kliendile ja
            Kasutajatele kättesaadavaks Veebilehel.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>4. LEPINGU TINGIMUSED</h3>
        <div class="flex flex-row">
          <span class="point-number">4.1</span>
          <p>
            Kasutustingimused on Lepingu lahutamatuks osaks. Haldaja võib iga
            Teenuse kohta kehtestada eraldi lisatingimusi (näiteks
            Privaatsuspoliitika, hinnapaketid ja kasutusjuhendid), mis loetakse
            Kasutustingimuste lahutamatuks osaks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">4.2</span>
          <p>
            Klient ja Haldaja võivad lisaks Kasutustingimustele kokku leppida ka
            Eritingimustes. Eritingimused tuleb vormistada vähemalt kirjalikku
            taasesitamist võimaldavas vormis ning need loetakse samuti Lepingu
            lahutamatuks osaks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">4.3</span>
          <p>
            Haldaja suhtleb Kliendi ja Kasutajatega eesti keeles, kui ei ole
            kokku lepitud teisiti. Leping ja muud Platvormi kasutamist ja
            Teenuste osutamist käsitlevad dokumendid on koostatud eesti keeles.
            Haldaja teeb kättesaadavaks ka nende dokumentide tõlked teistesse
            keeltesse, mille eesmärgiks on hõlbustada tingimustest arusaamist.
            Kui eestikeelse versiooni ja tõlke vahel esineb lahknevusi,
            lähtutakse eestikeelsest versioonist.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">4.4</span>
          <p>
            Kui Klient või Kasutaja ei nõustu Kasutustingimuste või nende
            muudatustega, ei ole tal õigust Platvormi ja Teenuseid kasutada ning
            ta peab selle koheselt lõpetama.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">4.5</span>
          <p>
            Haldajal on õigus igal ajal ühepoolselt muuta Kasutustingimusi,
            avaldades Veebilehel vastavalt Kasutustingimuste uue redaktsiooni
            koos muudatustega. Haldaja võib Kasutustingimusi muuta järgmistel
            juhtudel:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">4.5.1</span>
            <p>
              muudatus kehtivates õigusaktides või nende tõlgendamises;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.2</span>
            <p>
              Haldaja suhtes jõustunud Kasutustingimuste muutmiseks kohustav
              kohtulahend või haldusakt;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.3</span>
            <p>
              senise Teenuse muutumine, pakkumise lõpetamine või uue Teenuse
              kasutuselevõtmine;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.4</span>
            <p>
              olulised muudatused Platvormi tehnilises ülesehituses või
              funktsionaalsuses;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.5</span>
            <p>
              Klientide ja Kasutajate ettepanekud ja kaebused;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.6</span>
            <p>
              vajadus andmekaitse või muude turvameetmete tõhustamiseks;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.7</span>
            <p>
              muudatused Haldaja ärimudelis, töökorralduses ja/või volitustes;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.8</span>
            <p>
              tehnoloogilised arengud, mis võimaldavad parandada Platvormi ja
              Teenuste kasutajamugavust, kvaliteeti ja turvalisust;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">4.5.9</span>
            <p>
              muud ettenähtamatud juhud, kus Kasutustingimuste muutmine on
              mõistlikult põhjendatud või millega Haldajal ei olnud võimalik
              Kasutustingimuste kehtestamisel arvestada.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">4.6</span>
          <p>
            Haldaja teavitab Kliente Kasutustingimuste muudatustest Veebilehel
            ja eraldi teatega Kliendi kontaktandmete kaudu vähemalt 14 päeva
            enne nende jõustumist. Kui Klient ei nõustu nimetatud muudatustega,
            on tal õigus Leping lõpetada muudatuste jõustumisele eelneva 14
            päeva jooksul. Kui Klient jätkab Platvormi ja Teenuste kasutamist
            pärast nimetatud 14-päevase tähtaja möödumist, siis loetakse, et ta
            on Kasutustingimuste muudatustega nõustunud.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>5. KLIENDIKONTO JA KASUTAJAKONTO</h3>
        <div class="flex flex-row">
          <span class="point-number">5.1</span>
          <p>
            Platvormi põhifunktsionaalsuse kasutamiseks on vajalik Kliendikonto
            ja Kasutajakonto olemasolu. Kui füüsiline isik on seotud mitme
            Kliendiga, siis iga Kliendikonto all luuakse sellele füüsilisele
            isikule eraldi Kasutajakonto.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.2</span>
          <p>
            Kasutajakontosid haldab Klient, st Kliendil on oma äranägemisel
            õigus luua, muuta ja deaktiveerida Kasutajakontosid ja andmeid
            Kasutaja kohta. Haldajal on õigus ja kohustus luua uus Kasutajakonto
            ainult juhul, kui Kliendil puudub juurdepääs oma Kliendikontole ning
            ükski Kasutaja ei ole Kliendi seaduslik esindaja. Kliendi
            seaduslikuks esindajaks loetakse Kliendi registrikaardile kantud
            juhatuse liiget.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.3</span>
          <p>
            Oma konto kaudu igakordsel sisselogimisel Platvormile Kasutaja
            kinnitab, et:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">5.3.1</span>
            <p>
              kõik tema esitatud andmed ja antud kinnitused on täpsed, õiged,
              täielikud ja asjakohased;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">5.3.2</span>
            <p>
              ta on täielikult teovõimeline füüsiline isik (vähemalt
              18-aastane);
            </p>
          </div>
          <div class="flex">
            <span class="point-number">5.3.3</span>
            <p>
              tal on kõik õigused ja volitused Kliendi nimel Platvormi ja
              Teenuste kasutamiseks.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.4</span>
          <p>
            Eelnimetatud kinnituste õigsust eeldatakse ja Haldaja ei ole
            kohustatud, aga võib neid kontrollida.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.5</span>
          <p>
            Konto loomisel valivad Klient ja Kasutaja oma konto tunnuse ja
            parooli, mille abil on võimalik Platvormile sisse logida. Klient ja
            Kasutajad kohustuvad hoidma oma tunnust ja parooli salajas ning
            sellisel viisil, et need ei satuks kolmandate isikute kätte. Haldaja
            võimaldab Platvormile sisselogimist ka unikaalse Kliendile või
            Kasutajale määratud lingi kaudu, millisel juhul ei ole tarvis
            sisestada Kasutajakonto tunnust ega parooli. Selle võimaluse saab
            Klient soovi korral oma Kliendikonto seadetes aktiveerida.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.6</span>
          <p>
            Klient või Kasutaja peab koheselt Haldajat teavitama:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">5.6.1</span>
            <p>oma konto kuritarvitamisest;</p>
          </div>
          <div class="flex">
            <span class="point-number">5.6.2</span>
            <p>
              oma parooli kadumisest või sattumisest kolmandate isikute
              valdusesse;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">5.6.3</span>
            <p>
              oma ametikoha muutumisest või töölt lahkumisest või muust
              põhjusest, miks Kasutajal ei ole enam õigust Kliendi nimel
              Platvormi või Teenuseid kasutada.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.7</span>
          <p>
            Kasutustingimuste punktis 5.6 nimetatud juhul võtab Haldaja kõik
            mõistlikud meetmed selleks, et parool uuendada, kontot kaitsta või
            see kustutada.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">5.8</span>
          <p>
            Kliendikonto ja Kasutajakonto kehtivad tähtajatult kuni
            kustutamiseni või Lepingu lõppemiseni. Kui Klient on palunud
            Haldajal Kliendikonto kustutada, käsitleb Haldaja seda Lepingu
            lõpetamisena Kliendi poolt.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>6. TASUMINE</h3>
        <div class="flex flex-row">
          <span class="point-number">6.1</span>
          <p>
            Haldajal on õigus kehtestada Platvormi kasutamiseks tasusid,
            avaldades Veebilehel vastavad hinnapaketid. Klient peab Platvormi
            kasutamiseks valima nende seast endale sobiva tasulise hinnapaketi.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.2</span>
          <p>
            Klient kohustub tasuma Haldajale Platvormi kasutamise ja osutatud
            Teenuste eest vastavalt hinnapaketile, valitud lisadele ja Kliendi
            poolt valitud Kliendi- ja Kasutajakontode arvule.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.3</span>
          <p>
            Enne tasulise hinnapaketi valimist on esmakordsel Kliendil võimalik
            proovida Platvormi standardlahendust prooviperioodi (14 päeva)
            jooksul tasuta. Prooviperioodi eest Haldaja Kliendile arvet ei
            esita.
          </p>
        </div>

        <div class="flex flex-row">
          <span class="point-number">6.4</span>
          <p>
            Kui Klient soovib pärast tasuta prooviperioodi lõppemist Platvormi
            kasutamist jätkata tasulise hinnapaketi alusel, siis peab ta endale
            sobiva tasulise hinnapaketi välja valima ja tasuma ettemaksu
            järgmise perioodi eest hiljemalt prooviperioodi lõpuks. Kui Klient
            seda teinud ei ole, siis prooviperioodi lõppemisel on Haldajal õigus
            koheselt sulgeda Kliendikonto ja Kasutajakontod, sh kustutada kogu
            sinna lisatud Sisu, ning lõpetada Leping automaatselt.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.5</span>
          <p>
            Hinnapaketi alusel tasumine toimub perioodilise ettemaksu
            põhimõttel, st Klient tasub Platvormi kasutamise eest iga järgmise
            perioodi (tavaliselt kalendrikuu) eest ette.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.6</span>
          <p>
            Enne iga makseperioodi lõppemist esitatakse Kliendile arve või
            maksetaotlus. Klient peab tegema makse arvel või maksetaotlusel
            märgitud kuupäevaks.
          </p>
        </div>

        <div class="flex flex-row">
          <span class="point-number">6.7</span>
          <p>
            Kui Klient tasub krediitkaardiga, volitab Klient Haldajat oma
            krediitkaarti või pangakontot debiteerima kõigi maksmisele kuuluvate
            tasude ulatuses igal makseperioodil. Samuti volitab Klient Haldajat
            kasutama kolmandat osapoolt maksete töötlemiseks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.8</span>
          <p>
            Kui Klient tasub arve alusel, esitab Haldaja arve iga makseperioodi
            alguses. Kõik arvel näidatud summad kuuluvad maksmisele arvel
            märgitud kuupäevaks.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.9</span>
          <p>
            Klient saab ise Platvormil oma hinnapaketti muuta, aktiveerida
            lisasid ja muuta Kasutajakontode arvu. Sellistest muudatustest
            tulenev muudatus hinnas kajastub Kliendi järgmise perioodi eelmaksu
            arvel. Kõrgema tasuga hinnapaketist tulenevad kasutusvõimalused
            jõustuvad kohe pärast seda, kui Klient kinnitab paketi muutmist.
            Madalama tasuga hinnapaketi muudatused jõustuvad alates järgmise
            perioodi algusest.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.10</span>
          <p>
            Tasutud ettemaksu ei tagastata, sealhulgas kui:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">6.10.1</span>
            <p>
              Klient ei ole ettemakstud perioodi jooksul Platvormi kasutanud või
              on teinud seda ainult osaliselt;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">6.10.2</span>
            <p>
              Klient vahetab Platvormi hinnapaketti;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">6.10.3</span>
            <p>
              Klient lõpetab Lepingu ühepoolselt Kasutustingimuste või seaduse
              alusel, ilma et Haldaja oleks Lepingut rikkunud;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">6.10.4</span>
            <p>
              Haldaja lõpetab Lepingu ühepoolselt Kasutustingimuste või seaduse
              alusel.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.11</span>
          <p>
            Kui Klient rikub oma maksekohustust vähemalt 2 nädalat, on Haldajal
            õigus piirata Kliendi juurdepääsu Platvormile ja keelduda Teenuste
            osutamisest. Seejuures on Haldajal õigus arvestada tasu ka perioodi
            eest, mil ta rakendab Kliendi suhtes eelnimetatud piiranguid.
            Haldaja teavitab Klienti maksekohustuse rikkumise korral
            kavandatavate piirangute rakendamisest Kliendi e-posti aadressil
            vähemalt 2 korda vähemalt 2 tööpäevase vahega.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">6.12</span>
          <p>
            Kõik tasud on esitatud ilma maksudeta, mida Haldajal on õigus nõuda,
            kui see on asjakohane.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>7. SISU. ANDMETÖÖTLUSLEPING</h3>
        <div class="flex flex-col">
          <div class="flex flex-row">
            <span class="point-number">7.1</span>
            <p>
              Haldaja osutab Kliendile Platvormi kaudu Sisu veebimajutuse ja
              hooldamise teenust. Klient otsustab, millist Sisu, sealhulgas
              isikuandmeid, ta soovib, ja kas soovib, Platvormil töödelda.
              Sedasi töötleb Haldaja Kliendi eest Sisus sisalduvaid isikuandmeid
              ainult selleks, et pakkuda Platvormi ja osutada teenuseid, ning
              tegutseb selliste isikuandmete suhtes volitatud töötlejana, samas
              kui Klient tegutseb selliste isikuandmete suhtes vastutava
              töötlejana.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.2</span>
            <p>
              Kõikjal, peale demo versiooni (mis ei peaks sisaldama reaalseid
              andmeid, sealhulgas isikuandmeid), või paigaldamise ajal, ei ole
              Haldajal Sisule ligipääsu ilma Kliendi taotluseta (nt
              klienditoeks). Kasutajatel on ligipääs ainult Sisule, mille on
              Klient neile Platvormi kaudu kättesaadavaks teinud.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.3</span>
            <p>
              Andmesubjektide kategooriad, kelle Sisus sisalduvaid isikuandmeid
              Klient võib töödelda, võivad hõlmata muuhulgas:
              [identifitseerimisandmeid, kontaktandmeid, tööd puudutavad
              andmeid, sideandmeid, Gewodo Platvormi ja Teenuste kasutamisega
              seotud andmeid]. Isikuandmete kategooriad, mida Klient võib Sisus
              töödelda võivad muuhulgas hõlmata: [identifitseerimisandmeid,
              kontaktandmeid, tööd puudutavad andmeid, sideandmeid, Gewodo
              Platvormi ja Teenuste kasutamisega seotud andmeid].
            </p>
          </div>
          <div class="flex flex-col">
            <div class="flex">
              <span class="point-number">7.4</span>
              <p>
                Klient ja Haldaja soovivad nõuetekohaselt järgida kõiki enda
                vastavaid kohustusi, mille alus on Euroopa Parlamendi ja Nõukogu
                27. aprilli 2016 määrus (EL) 2016/679, füüsiliste isikute kaitse
                kohta isikuandmete töötlemisel ja selliste andmete vaba
                liikumise kohta (isikuandmete kaitse üldmäärus e GDPR) ning
                igasugused muud asjakohased kohalduvad andmekaitse eeskirjad
                (koos Andmekaitsealased õigusaktid).
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.5</span>
            <p>
              Käesolevate Kasutustingimuste tähenduses on terminitel „vastutav
              töötleja“, „volitatud töötleja“, „isikuandmed“, „andmesubjekt“,
              „isikuandmetega seotud rikkumine“ GDPR-is antud tähendus.
              „Alamtöötleja“ tähendab teist volitatud töötlejat, kelle on
              Haldaja kaasanud Sisus toodud isikuandmete töötlemiseks.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.6</span>
            <p>
              Klient kui andmete vastutav töötleja vastutab täielikult tema
              Platvormi ja Teenuste abil Sisus töödeldavate igasuguste
              isikuandmete eest. Klient kinnitab, et tema isikuandmete
              töötlemise tavad on täielikult kooskõlas Andmekaitsealaste
              õigusaktidega, sealhulgas, et tal on Sisus sisalduvate
              isikuandmete töötlemiseks õiguslik alus vastavalt käesolevas
              dokumendis sätestatule ning, et ta on töötlemisest andmesubjekte
              korrapäraselt teavitanud. Kui Kasutaja lisab Platvormile Sisu,
              peab ta tagama selle täpsuse, õigsuse, terviklikkuse, asjakohasuse
              ja selle vastavuse Lepingule, heale tavale ja õigusaktidele.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.7</span>
            <p>
              Haldaja:
            </p>
          </div>
          <div class="flex flex-col ml-5">
            <div class="flex">
              <span class="point-number">7.7.1</span>
              <p>
                töötleb Sisus sisalduvaid isikuandmeid ainult Kliendi
                õiguspäraste dokumenteeritud juhiste alusel ning Platvormi
                pakkumise ja Teenuste osutamise eesmärgil, välja arvatud kui
                seda on nõutud Andmekaitsealaste õigusaktidega. Sellisel juhul
                teavitab Haldaja Klienti eelnevalt sellisest vajadusest, kui
                seadus sellise teabe edastamist ei keela;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.7.2</span>
              <p>
                tagab, et isikud, kellel on volitus töödelda Sisus sisalduvaid
                isikuandmeid, on võtnud endale konfidentsiaalsuskohustuse;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.7.3</span>
              <p>
                võttes arvesse töötlemise ja Haldajale kättesaadava teabe
                olemust, aitab Kliendil tagada, et viimane järgib oma kohustusi
                vastavalt GDPR-i artiklitele 32 kuni 36;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.7.4</span>
              <p>
                teavitab Klienti kui Haldaja arvates rikub Kliendi juhis
                Andmekaitsealaseid Õigusakte.
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.8</span>
            <p>
              Haldaja rakendab asjakohaseid tehnilisi ja korralduslikke
              turvameetmeid võttes arvesse (i) tehnika taset, (ii) juurutamise
              kulusid, (iii) töötlemise olemust, ulatust, konteksti ja eesmärke
              ning (iv) andmesubjekti kantavaid riske. Sellised turvameetmed
              hõlmavad muuhulgas krüptitud säilitamist ja ligipääsu
              reguleerimist. Meetmete valikul eeldab Haldaja, et Platvormi ja
              Teenuseid kasutatakse vastavalt nende sihtotstarbele
              (ärijuhtimine, projektijuhtimine, ajahaldus, töökava koostamine ja
              jälgimine, finantsjuhtimine, aruandlus, jne), mis ei tohiks
              hõlmata mitte mingisuguste isikuandmete erikategooriate töötlemist
              (vaata ka eespool punkti 7.3.).
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.9</span>
            <p>
              Haldaja teavitab viivitamatult Klienti, kui ta saab
              andmesubjektilt taotluse seoses oma isikuandmetega Kliendi Sisus
              ning lubab Kliendil sellele vastata. Haldaja ei vasta
              andmesubjekti taotlusele ilma Klienti eelneva kirjaliku
              nõusolekuta. Võttes arvesse töötlemise olemust, aitab Haldaja
              Klienti niivõrd, kuivõrd on see võimalik asjakohaste tehniliste ja
              korralduslike meetmetega, et täita Kliendi kohustus vastata
              andmesubjekti taotlusele vastavalt Andmekaitsealastele
              Õigusaktidele.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.10</span>
            <p>
              Klient volitab Haldajat kasutama järgmisi alamtöötlejate
              kategooriaid:
            </p>
          </div>
          <div class="flex flex-col ml-5">
            <div class="flex">
              <span class="point-number">7.10.1</span>
              <p>
                veebimajutuse teenuse osutajad;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.10.2</span>
              <p>
                haldamise ja säilitamise pakkujad;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.10.3</span>
              <p>
                e-posti teenuse osutajad;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.10.4</span>
              <p>
                kliendisuhete haldamise ja tagasiside teenuse osutajad;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.10.5</span>
              <p>
                makseteenuse osutajad.
              </p>
            </div>
          </div>
          <div class="flex">
            <p>
              Alamtöötlejate täielik nimekiri on saadaval vastava taotluse
              esitamisel.
            </p>
          </div>
          <div class="flex">
            <p>
              Haldaja teavitab Klienti igasugustest plaanitud muudatustest, mis
              puudutavad teiste alamtöötlejate kategooriate lisamist või
              vahetust. Klient võib esitada vastuväite Haldaja uue
              alamtöötlejate kategooria kasutamisele, teavitades sellest
              Haldajat viivitamatult 10 tööpäeva jooksul pärast Haldaja
              teavituse kätte saamist. Juhul, kui Klient esitab vastuväite uue
              alamtöötlejate kategooria suhtes, võtab Haldaja tarvitusele
              mõistlikud jõupingutused, et pakkuda Kliendile Platvormi ja
              Teenuseid ilma sellise alamtöötlejate kategooriata. Kui see ei ole
              võimalik, võib Klient Lepingu lõpetada. Haldaja kehtestab
              alamtöötlejatele samad andmekaitsekohustused, mis on sätestatud
              käesolevas dokumendis. Kui alamtöötleja ei täida enda
              andmekaitsekohustusi vastutab Haldaja Kliendi ees alamtöötleja
              kohustuste täitmise eest.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.11</span>
            <p>
              Haldaja ja tema alamtöötlejad võivad edastada isikuandmeid
              väljapoole EL-i ainult siis, kui neil on selleks seaduslik alus,
              sealhulgas andmete saajale, kes on: i) riigis, kus on isikuandmete
              kaitse tagatud adekvaatsel tasemel (USA-s hõlmab see ettevõtteid,
              kellel on Privacy Shield-i sertifikaat); või (ii) dokumendi
              alusel, mis katab EL-i nõudeid isikuandmete edastamiseks väljapool
              EL-i olevatele volitatud andmetöötlejatele. Soovi korral anname
              isikuandmete edastamisest väljapoole EL-i üksikasjalikumat teavet.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.12</span>
            <p>
              Haldaja teavitab Klienti ilma asjatu viivituseta e-posti teel
              pärast seda, kui on saanud teada isikuandmetega seotud rikkumisest
              ning teeb Kliendiga isikuandmetega seotud rikkumisest tulenevalt
              mõistlikult koostööd. Sellisel juhul võib Klient kasutada Haldaja
              edastatud isikuandmetega seotud rikkumist puudutavat teavet ainult
              selleks, et tagada ja/või näidata enda kooskõla Andmekaitsealaste
              õigusaktidega. Klient hoiab seda teavet konfidentsiaalsena, välja
              arvatud siis, kui tegu on Kliendi enda konfidentsiaalse teabega
              või kui selline teave tuleb avaldada vastavalt mis tahes
              kohalduvatele õigusaktidele.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.13</span>
            <p>
              Haldaja teeb Kliendi kirjalikul taotlusel viimasele kättesaadavaks
              teabe, mis on vajalik näitamaks, et ta järgib käesolevas
              paragrahvis ja GDPR-i artiklis 28 sätestatud kohustusi, eeldusel,
              et taotletud teave on Haldaja valduses või kontrolli all. Kui see
              osutub Kliendile ebapiisavaks, peab Haldaja tegema Kliendiga
              koostööd, sealhulgas lubama Kliendi või Kliendi volitatud ja
              Haldaja aktsepteeritud muu audiitori korraldatavaid mõistlikke
              auditeid, sealhulgas inspektsioone, ja neile kaasa aitama.
              Selliste auditite ja inspektsioonide üksikasjad lepitakse Poolte
              vahel kokku, kuid olenemata sellest kohaldub allpool toodu:
            </p>
          </div>
          <div class="flex flex-col ml-5">
            <div class="flex">
              <span class="point-number">7.13.1</span>
              <p>
                Haldaja on kohustatud andma Kliendile teavet, toimikuid ja
                dokumente ainult siis, kui see on mõistlikult vajalik, et
                näidata tema kohustuste täitmist vastavalt käesolevale
                paragrahvile 7 ja GDPR-i artiklile 28 seoses Sisus sisalduvate
                isikuandmetega;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.13.2</span>
              <p>
                Haldaja ei avalda mingit teavet, mingeid toimikuid ega muid
                dokumente, millele kohalduvad tema ärisaladused;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.13.3</span>
              <p>
                Haldaja ei avalda mingit teavet, mingeid toimikuid ega muid
                dokumente, mis tooks kaasa kohalduvatest õigusaktidest või muude
                klientide või isikutega sõlmitud lepingutest tuleneva
                konfidentsiaalsuskohustuste rikkumise;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.13.4</span>
              <p>
                Haldaja ei avalda mingit teavet, mingeid toimikuid ega muid
                dokumente, mis on seotud asjaga, mille suhtes on käimas, on
                menetluses või ähvardab tulla kohtuvaidlus või muu vaidluse
                lahendamise mehhanism Kliendi ja Haldaja vahel;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.13.5</span>
              <p>
                Klient käsitleb konfidentsiaalsena kogu teavet, kõiki toimikuid
                ja muid dokumente, mis on Kliendile antud vastavalt käesolevale
                paragrahvile 7;
              </p>
            </div>
            <div class="flex">
              <span class="point-number">7.13.6</span>
              <p>
                Klient ei või rakendada oma õigust auditi tegemiseks käesoleva
                paragrahvil 7 alusel sagedamini kui üks kord kalendriaasta
                jooksul, välja arvatud siis, kui tal on põhjendatud kahtlus
                Haldaja kohustuste täitmises.
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.14</span>
            <p>
              Kasutajad ei või Platvormile lisada Sisu, mis sisaldab viiruseid
              või arvutiprogramme ja faile, mis kahjustavad või katkestavad muul
              viisil Platvormi tavapärast töötamist või mis on salvestatud
              Haldaja või Kasutaja arvutites ja katkestavad või kahjustavad
              nende normaalset töötamist. Klient vastutab selle tagamise eest.
            </p>
          </div>
          <div class="flex flex-row">
            <span class="point-number">7.15</span>
            <p>
              Klient annab Haldajale Sisu jaoks vajalikud õigused (sealhulgas
              lihtlitsentsi, mis puudutab igasugust intellektuaalomandi
              õigustega kaitstud Sisu) ning kinnitab, et tal on õigus niiviisi
              toimida.
            </p>
          </div>
        </div>
      </div>
      <div class="prg-container">
        <h3>8. INTELLEKTUAALNE OMAND</h3>
        <div class="flex flex-row">
          <span class="point-number">8.1</span>
          <p>
            Platvorm, Veebileht ning nende mis tahes osad ja elemendid
            (sealhulgas andmebaasid ja tarkvara, ärinimed, kaubamärgid,
            ärisaladused, domeeninimed jms) on ja võivad olla kaitstud
            intellektuaalse omandi õigustega, mis kuuluvad Haldajale, tema
            töötajatele või Haldaja koostööpartneritele.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">8.2</span>
          <p>
            Haldaja lubab Lepingu kehtivuse ajal Kliendil ja Kasutajatel
            kasutada Platvormi funktsionaalsust oma sisemisteks vajadusteks
            kooskõlas Lepingu tingimustega tavalisel otstarbel, milleks Platvorm
            on mõeldud. Haldaja ei anna Kliendile ega Kasutajale mis tahes muid
            litsentse ega õigusi ning Kliendil ega Kasutajal ei teki Platvormile
            ega Veebilehele intellektuaalse omandi õigusi.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">8.3</span>
          <p>
            Kasutaja ega Klient ei või Platvormi, Veebilehte ega nende
            komponente ilma Haldaja eelneva kirjaliku nõusolekuta muuta,
            kopeerida, paljundada, levitada, töödelda, tõlkida, teha
            väljavõtteid, edastada, lülitada teistesse andmebaasidesse ega
            üldsusele kättesaadavaks teha ega muul moel kasutada Platvormi või
            Veebilehe suhtes tekkinud intellektuaalse omandi õigusi. Samuti ei
            ole Kliendil ega Kasutajal õigust anda Platvormi, Veebilehe või
            nende komponentide kasutamiseks all-litsentse ega luua nende põhjal
            uusi intellektuaalse omandi objekte. Keelatud on Platvormi,
            Veebilehe või nende mis tahes osi ilma Haldaja eelneva kirjaliku
            nõusolekuta müüa, rentida, litsentsida, liidestada Kliendi või
            kolmandate isikute süsteemidega või kasutada neid mis tahes
            programmide abil, mis koormavad või segavad Platvormi või Veebilehe
            tööd või mis moonutavad Sisu.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>9. TUGI-, HOOLDUS- JA ARENDUSTEENUSED</h3>
        <div class="flex flex-row">
          <span class="point-number">9.1</span>
          <p>
            Haldaja uuendab pidevalt Platvormi toimimise aluseks olevaid
            mehhanisme, et pakkuda Klientidele ja Kasutajatele kvaliteetseid
            Teenuseid. Selle eesmärgi täitmiseks võib Haldaja aeg-ajalt
            Platvormi ja selle komponente muuta, samuti muuta nõudeid Platvormi
            ja selle kaudu osutatavate Teenuste kasutamiseks vajalikule riist-
            ja tarkvarale. Haldaja teavitab Klienti ja Kasutajaid olulisematest
            muudatustest ette mõistliku aja enne nende jõustumist, võttes
            arvesse muudatuse tõenäolist mõju Klientidele ja Kasutajatele.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">9.2</span>
          <p>
            Haldaja pakub Platvormi kasutamiseks mitmesuguseid abimaterjale, mis
            on kättesaadavad Veebilehel. Probleemide, küsimuste ja ettepanekute
            korral on Kliendil ja Kasutajatel võimalik Haldajaga ühendust võtta
            Veebilehel toodud kontaktide kaudu.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">9.3</span>
          <p>
            Kui Klient kasutab Platvormi tasulise hinnapaketi alusel, siis tagab
            Haldaja Kliendile ka asjakohased versiooniuuendused ja värskendused
            Platvormi tehnilises lahenduses.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">9.4</span>
          <p>
            Haldaja võib ajutiselt peatada ligipääsu Platvormile, kui see on
            vajalik Platvormi muutmise, hooldamise või uuendamise eesmärgil,
            kolmandate isikute asendus-, muutmis- või hooldustööde tõttu või
            muudel juhtudel, mis tulenevad õigusaktidest või pädevate
            ametkondade otsustest. Regulaarsetest hooldustöödest teavitab
            Haldaja Kliente ja Kasutajaid ette Platvormi vahendusel vähemalt 2
            tööpäeva. Selleks, et võimalikult vähe häirida Platvormi kasutamist,
            teostab Haldaja korralisi hooldus- ja/või arendustöid tööpäeviti
            vahemikus kell 18.00-07.00 (CET) või nädalavahetustel.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">9.5</span>
          <p>
            Kui Platvormis esineb vigu või mis tahes muid
            funktsionaalsushäireid, mille tulemusel Platvormi kasutamine on
            takistatud, siis teeb Haldaja kõik mõistlikult võimaliku, et need
            häired kõrvaldada esimesel võimalusel, kuid mitte hiljem kui 48
            tunni jooksul alates veateate saamisest. Kui funktsionaalsushäired
            on vähetähtsad, võib Haldaja need kõrvaldada ka hiljem kui 48 tunni
            jooksul veateate saamisest. Sellisel juhul teavitab Haldaja sellest
            Klienti.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>10. LEPINGU LÕPPEMINE</h3>
        <div class="flex flex-row">
          <span class="point-number">10.1</span>
          <p>
            Kliendil on õigus Leping mis tahes põhjusel igal ajal ühepoolselt
            üles öelda, teatades sellest Haldajale e-posti teel või Platvormi
            kaudu.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">10.2</span>
          <p>
            Haldajal on õigus Leping mis tahes põhjusel ühepoolselt üles öelda,
            teatades sellest Kliendile e-posti teel või Platvormi kaudu ette 30
            päeva enne kavandatavat Lepingu lõppemise kuupäeva. Sellisel juhul
            loetakse Leping lõppenuks pärast 30 päeva möödumist ülesütlemise
            avalduse esitamisest.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">10.3</span>
          <p>
            Haldajal on õigus ühepoolselt Leping koheselt, ilma etteteatamata
            üles öelda, kui:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">10.3.1</span>
            <p>
              Klient on enda kohta esitanud valeandmeid;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">10.3.2</span>
            <p>
              Klient ei ole Teenust kasutanud järjest vähemalt ühe aasta
              jooksul;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">10.3.3</span>
            <p>
              selgub, et Kliendi nimel Platvormi, Teenuseid või Kliendikontot
              kasutanud isikul puudub esindusõigus Kliendi nimel tegutsemiseks;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">10.3.4</span>
            <p>
              on välja kuulutatud Kliendi pankrot või saneerimine, samuti kui
              Kliendi suhtes on alustatud sundlõpetamine või likvideerimine;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">10.3.5</span>
            <p>
              Klient tahtlikult või raskelt hooletult põhjustab Haldajale kahju;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">10.3.6</span>
            <p>
              teistel Lepingus nimetatud põhjustel.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">10.4</span>
          <p>
            Kummalgi Poolel on õigus Leping ilma etteteatamata üles öelda, kui
            teine Pool rikub Lepingut ega ole seda rikkumist kõrvaldanud talle
            antud täiendava mõistliku tähtaja jooksul.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">10.5</span>
          <p>
            Lepingu lõppemisel sulgeb Haldaja vastavad kontod ning, kui ei ole
            kokku lepitud teisiti, kustutab nendel asuva Sisu 90 päeva pärast
            Lepingu lõppemist. Haldaja ei kustuta sellist Sisu, mille
            säilitamine on kohustuslik kohalduva õiguse alusel. Klient saab
            laadida alla Sisu koopia üldtunnustatud formaadis või, kui
            Kliendikonto peaks mingil põhjusel olema mittekasutatav, paluda
            Haldajal edastada see 90 päeva jooksul Lepingu lõppemisest.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>11. HALDAJA ÕIGUSKAITSEVAHENDID</h3>
        <div class="flex flex-row">
          <span class="point-number">11.1</span>
          <p>
            Haldajal ei ole kohustust kontrollida Kasutajate poolt Platvormile
            lisatud Sisu ja Kasutaja toiminguid Platvormil. Samuti ei ole
            Haldaja kohustatud jälgima Kasutajate tegevust ega teavet või Sisu,
            mida nad Platvormile lisavad või selle kaudu edastavad, vahemällu
            salvestavad või talletavad. Samas on Haldajal infoühiskonna teenuse
            seadusest tulenev kohustus teavitada pädevaid järelevalveasutusi
            võimalikust ebaseaduslikust tegevusest või pakutavast teabest ning
            identifitseerida neid Kliente ja Kasutajaid, kellele ta osutab
            andmete talletamise teenust.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">11.2</span>
          <p>
            Kui Klient või Kasutaja rikub Lepingut, Platvormil kehtivaid häid
            tavasid või õigusakte, on Haldajal õigus:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">11.2.1</span>
            <p>
              kõrvaldada rikkumine või õigusvastane Sisu;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">11.2.2</span>
            <p>
              nõuda rikkumise lõpetamist ja käitumise või Sisu kooskõlla viimist
              Lepingu, hea tava või õigusaktidega;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">11.2.3</span>
            <p>
              ajutiselt tõkestada Kliendi või Kasutaja juurdepääs Platvormile
              või selle mis tahes osale, sh ajutiselt sulgeda Kasutajakonto;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">11.2.4</span>
            <p>
              piirata Kliendi või Kasutaja kasutusõigusi.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">11.3</span>
          <p>
            Kui Kliendi või Kasutaja rikkumine on korduv või muul põhjusel
            oluline, siis on Haldajal õigus keelata püsivalt Kliendil või
            Kasutajal vastava Teenuse või Platvormi osa kasutamine, kustutada
            Kasutajakonto või Leping lõpetada ilma ette teatamata.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">11.4</span>
          <p>
            Haldaja võib Platvormilt kaebuse alusel eemaldatud Sisu uuesti
            Platvormile lisada või sellele juurdepääsu taastada juhul, kui
            Haldajale esitatakse veenvad tõendid Sisu vastavuse kohta Lepingule,
            Platvormil kehtivatele headele tavadele või õigusaktidele.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>12. VASTUTUS</h3>
        <div class="flex flex-row">
          <span class="point-number">12.1</span>
          <p>
            Haldaja pakub Platvormi „nii nagu on“ põhimõttel. Haldaja ei anna
            Kliendile lisaks Kasutustingimustes sõnaselgelt kirjeldatule mingeid
            täiendavaid lubadusi ega võta kohustusi Platvormi kaudu
            kättesaadavate konkreetsete funktsioonide, õigusaktidele vastavuse,
            kindlaks eesmärgiks kasutamiskõlblikkuse, töökindluse,
            kättesaadavuse ega Kliendi vajadustele vastavuse kohta, v.a kui
            Pooled on selles eraldi kokku leppinud.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">12.2</span>
          <p>
            Ulatuses, millises see on kohalduva õiguse alusel lubatud, vastutab
            Haldaja ainult siis, kui ta on oma tegevuses või tegevusetuses
            süüdi. Haldaja koguvastutus (sh intressid) kõigi Lepingu rikkumisega
            seotud nõuete eest on piiratud kahekordse Lepingujärgse tasu või
            tegeliku kahjuga, olenevalt sellest, kumb on väiksem. Ulatuses,
            millises see on kohalduva õiguse alusel lubatud, ei vastuta Haldaja
            Kliendile või Kasutajale tekitatud saamata jäänud tulu,
            puhtmajandusliku kahju ega mittevaralise kahju eest, samuti muude
            kaudsete, erilaadsete, tegevuse tulemusest johtuvate, hoiatava või
            karistava iseloomuga kahjude eest. Samuti ei vastuta Haldaja kahju
            ja muude tagajärgede eest, mis on tekkinud järgmistel põhjustel:
          </p>
        </div>
        <div class="flex flex-col ml-5">
          <div class="flex">
            <span class="point-number">12.2.1</span>
            <p>
              Veebileht või Infosüsteem ei toimi mõnes veebisirvijas;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.2</span>
            <p>
              Kliendi ja Kasutaja või Klientide või Kasutajate vahel tekkinud
              vaidlused;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.3</span>
            <p>
              Kliendi või Kasutaja poolt Platvormi vahendusel kolmandate
              isikutega tehtud tehingute kehtivuse, täitmise ja õiguspärasuse
              eest;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.4</span>
            <p>
              Kliendi või Kasutaja poolt Platvormile Sisu lisamine, mis ei ole
              või mida ei kasutata kooskõlas Lepingu, Platvormil kehtiva hea
              tava või õigusaktidega;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.5</span>
            <p>
              Kliendi või Kasutaja poolt Platvormile lisatud isikuandmete
              töötlemine vastuolus kohalduvate Andmekaitsealaste õigusaktidega;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.6</span>
            <p>
              Kliendi poolt Kasutajakontode haldamine, sh Kliendikonto või
              Kasutajakonto kaudu toimepandud õigusrikkumised või
              Kasutustingimuste rikkumised, sõltumata sellest, kas tegemist oli
              Kliendikonto või Kasutajakonto kasutamiseks volitatud isikuga;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.7</span>
            <p>
              õigusaktides ja nende tõlgendamises toimunud muudatused, nende
              mõjud Klientide või Kasutajate äritegevusele ning vastavate
              muudatuste kajastamine Platvormis, kui see ei ole Haldajale
              õigusaktidest või Haldaja suhtes jõustunud kohtulahenditest
              tulenevalt kohustuslik;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.8</span>
            <p>
              vääramatu jõud ja muud Haldajast mitteolenevad vead või häired,
              mis takistavad Kliendil või Kasutajatel Platvormi, Veebilehe
              ja/või Teenuste kasutamist (näiteks katkestused internetiühenduses
              jne);
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.9</span>
            <p>
              Kliendi või Kasutaja seadmetes esinevad vead, kahjustused või
              Platvormi või Veebilehe kasutamiseks sobimatud seadistused;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.10</span>
            <p>
              korraliste hooldus- ja/või arendustööde tõttu Platvormi, Veebilehe
              ja/või Teenuste kasutamisel tekkinud viivitused, katkestused või
              tõrked;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.11</span>
            <p>
              andmete töötlemine kolmandate isikute poolt, kellele Haldaja on
              need edastanud Kliendi või Kasutaja nõusolekul, v.a alamtöötlejad,
              kes töötlevad Sisus olevaid isikuandmeid;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.12</span>
            <p>
              kui Haldaja saab teada Platvormi vahendusel toime pandud või
              kestvast õigusrikkumisest, kõrvaldab selle või tõkestab sellele
              juurdepääsu või võtab aktiivselt tarvitusele muud meetmed
              õigusrikkumise lõpetamiseks või tagajärgede kõrvaldamiseks;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.13</span>
            <p>
              Haldaja õiguskaitsevahendite kasutamine ja selle tõttu Kliendile,
              Kasutajale või kolmandale isikule tekkinud kahju välja arvatud
              juhul, kui hiljem selgub, et tegemist ei olnud rikkumisega;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.14</span>
            <p>
              Kliendikonto või Kasutajakonto parooli kadumine või volitamata
              kolmandate isikute kätte sattumine või kolmandate isikute poolt
              kasutamine;
            </p>
          </div>
          <div class="flex">
            <span class="point-number">12.2.15</span>
            <p>
              kolmandate isikute süsteemides (näiteks Outlook, Dropbox,
              HansaWorld, Krediidiinfo jt) esinevad tõrked ja puudused, mis
              mõjutavad Platvormi ja/või Teenuste toimimist ning kättesaadavust.
            </p>
          </div>
        </div>
        <div class="flex flex-row">
          <span class="point-number">12.3</span>
          <p>
            Klient hüvitab Haldajale igasuguse kahju, mis on Haldajale tekkinud
            mis tahes nõuetest, kohustustest, hagidest, kaebustest,
            süüdistustest, kuludest, kulutustest, jms (sh õigusabikuludest), mis
            on tingitud sellest või seotud sellega, et Klient on rikkunud oma
            käesolevast Lepingust või Andmekaitsealastest õigusaktidest
            tulenevaid kohustusi isikuandmete vastutava töötlejana.
          </p>
        </div>
      </div>
      <div class="prg-container">
        <h3>13. KOHALDUV ÕIGUS JA VAIDLUSTE LAHENDAMINE</h3>
        <div class="flex flex-row">
          <span class="point-number">13.1</span>
          <p>
            Lepingule kohaldatakse Eesti Vabariigis kehtivaid õigusakte.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">13.2</span>
          <p>
            Kui Klient ei ole rahul Haldaja tegevusega, on tal õigus esitada
            Haldajale kaebus. Haldaja teeb pingutusi, et lahendada lahkarvamused
            läbirääkimiste teel. Ka muud Kliendi ja Haldaja vahelised vaidlused
            seoses Lepinguga püütakse lahendada esmalt läbirääkimiste teel.
          </p>
        </div>
        <div class="flex flex-row">
          <span class="point-number">13.3</span>
          <p>
            Kui kaebuse või muu vaidluse lahendamine läbirääkimiste teel
            ebaõnnestub, siis lahendatakse vaidlus Eesti Vabariigis Harju
            Maakohtus.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.terms {
  .point-number {
    @apply self-start pr-3;
  }
  p {
    @apply mb-3;
  }
  h1,
  h2,
  h3 {
    @apply mb-3;
  }
  ul {
    li {
      @apply ml-5 mb-5;
    }
    list-style-type: disc;
  }
}
</style>
